<template>
<div>
    <div>
        <swiper :options="swiperOption">
            　<swiper-slide>
                <el-image style="width: 100vw; height: 30vh" fit="fill" :src="require('@/assets/item.png')"></el-image>
            </swiper-slide>
            <!-- <swiper-slide>
                <div class="timer">
                    距离英语专业八级考试还有{{days}}天!
                </div>
            </swiper-slide> -->
            <swiper-slide>
                <div class="timer mdays">
                    距离英语专业八级考试还有{{days}}天!
                    <!-- with小张的{{mdays}}天! -->
                </div>
            </swiper-slide>
            　　<div class="swiper-pagination" slot="pagination"></div>
            <!-- 　　<div class="swiper-button-prev" slot="button-prev"></div>
            　　<div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
    </div>

    <div class="content">
        <div class="app">
            <router-link to="/cloud">
                <div class="applogo">
                    <el-image class="image" style="width: 16vw; height: 16vw" fit="cover" :src="require('@/assets/cloud.png')"></el-image>
                </div>
            </router-link>
            <div class="appname">云盘</div>
        </div>

        <div class="app">
            <router-link to="/music">
                <div class="applogo">
                    <el-image class="image" style="width: 16vw; height: 16vw" fit="cover" :src="require('@/assets/music.png')"></el-image>
                </div>
            </router-link>
            <div class="appname">音乐</div>
        </div>
        <div class="app">

            <router-link to="/wrong">
                <div class="applogo">
                    <el-image class="image" style="width: 16vw; height: 16vw" fit="cover" :src="require('@/assets/cuotiben.png')"></el-image>
                </div>
            </router-link>

            <div class="appname">错题本</div>
        </div>
        <div class="app">
            <router-link to="/clockin">
                <div class="applogo">
                    <el-image class="image" style="width: 16vw; height: 16vw" fit="cover" :src="require('@/assets/daka_1.png')"></el-image>
                </div>
            </router-link>
            <div class="appname">i打卡</div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            mdays: 0,
            days: 0,
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true // 允许点击小圆点跳转
                },
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: true // 手动切换之后继续自动轮播
                },
                loop: false,

            },
        };
    },
    methods: {
        getDistanceSpecifiedTime() {
            // 指定日期和时间
            var EndTime = new Date('2022/4/23');
            // 当前系统时间
            var NowTime = new Date();
            var t = EndTime.getTime() - NowTime.getTime();
            var d = Math.floor(t / 1000 / 60 / 60 / 24);
            this.days = d
            // var h = Math.floor(t / 1000 / 60 / 60 % 24);
            // var m = Math.floor(t / 1000 / 60 % 60);
            // var s = Math.floor(t / 1000 % 60);
            // var html = d + " 天" + h + " 时" + m + " 分" + s + " 秒";
            // console.log(html);
        },
        getTime() {
            // 指定日期和时间
            var EndTime = new Date('2020/2/25');
            // 当前系统时间
            var NowTime = new Date();
            var t = NowTime.getTime() - EndTime.getTime();
            var d = Math.floor(t / 1000 / 60 / 60 / 24);
            this.mdays = d
            // var h = Math.floor(t / 1000 / 60 / 60 % 24);
            // var m = Math.floor(t / 1000 / 60 % 60);
            // var s = Math.floor(t / 1000 % 60);
            // var html = d + " 天" + h + " 时" + m + " 分" + s + " 秒";
            // console.log(html);
        }
    },
    created() {
        this.getDistanceSpecifiedTime()
        this.getTime()
    }
};
</script>

<style scoped>
.el-carousel__item h3 {
    color: #475669;
    font-size: 1rem;
    opacity: 0.75;
    line-height: 30vh;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #a199bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.content {
    /* display: -webkit-inline-flex;  */
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* align-items: flex-start; */
    padding: 5px 5vw;
}

.app {
    background-color: #fff;
    margin-right: 5.5vw;
    display: inline-block;
    text-align: center;
    /* position: relative; */
    margin-bottom: 1vh;
}

.app:nth-child(4n) {
    margin-right: 0px;
}

.applogo {
    width: 18vw;
    height: 18vw;
    background-color: rgb(241, 243, 245);
    border-radius: 5vw;
    overflow: hidden;
}

.image {
    display: center;
    margin-top: 1vw;
}

.appname {
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-size: 0.9rem;
}

div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.timer {
    font-size: 1.6rem;
    color: rgba(11, 12, 12, 0.644);
    background-color: rgb(51, 207, 173);
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mdays {
    background: url("https://thesongof404.top:3000/blog/背景22.png");
}
</style>
